import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import i18next from "i18next";

const Banner = observer(() => {
  const navigate = useNavigate();

  const {
    authStore: { banners, top_coin, games, authenticated },
  } = useStores();

  return (
    <div className="css-1pysja1">
      <div className="bg-box">
        <div className="css-1t9l9dt" style={{ width: "100%" }}>
          <div className="css-8hstpq-bg css-8hstpq">
            <div className="css-b22026 reg-input-box">
              <div className="css-1xamyaw  css-1xamyaw-l">
                <div className="css-1xamyaw-content">
                  <p
                    className="fw"
                    style={{
                      fontSize: "38px",
                      textAlign: "initial",
                      color: "#ffffff",
                    }}
                  >
                    Provide a variety of CFD contracts, foreign exchange,
                    precious metals, cryptocurrency trading
                  </p>
                  <p
                    style={{
                      fontSize: "38px",
                      color: "#ffffff",
                      textAlign: "initial",
                    }}
                  >
                    {`The world's leading derivatives market`}
                  </p>
                  <p
                    style={{
                      fontSize: "18px",
                      color: "#b0b9c3",
                      textAlign: "initial",
                    }}
                  >
                    Quickly join BITTOKEN to start trading
                  </p>
                  <form className="form-inline">
                    <input
                      type="email"
                      className="form-control mb-2 mr-sm-2 reginput"
                      placeholder="Please enter your email"
                      id="email"
                    />
                    <button
                      type="submit"
                      className=" mb-2 ml-2 regbtn"
                      onClick={() =>
                        authenticated
                          ? navigate("/member")
                          : navigate("/auth/login")
                      }
                    >
                      {i18next.t("sign_up")}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Banner;
