import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faCopy,
  faGlobe,
  faWallet,
  faShield,
  faQuestionCircle,
  faBell,
  faArrowLeftLong,
  faUserCheck,
  faCheck,
  faLock,
  faHeadphones,
  faPowerOff,
  faUser,
  faHomeAlt,
} from "@fortawesome/free-solid-svg-icons";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate } from "react-router-dom";
import { useStores } from "_common/hooks";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { observer } from "mobx-react-lite";
import i18next from "i18next";
import { handleCopyLink } from "_common/utils/helper";
import bgMember from "assets/img/bg-member.png";

const MemberPage = observer(() => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user, game_wallet, general, clear },
  } = useStores();

  const { mutate: onLogout } = useMutation(AuthApi.logout, {
    onSuccess: (res) => {
      clear();
    },
    onError: (error) => {
      clear();
      const errorMessage =
        i18next.t(error?.message) ?? i18next.t("action_failed_msg");
      NotificationManager.error(errorMessage);
    },
  });

  const onGetProfile = () => {
    queryClient.invalidateQueries(["get_profile"]);
  };

  return (
    <div className="member-page">
      <div className="mu-card">
        <div className="mu-card-header">
          <div className="mu-avatar">
            <FontAwesomeIcon icon={faUser} />
          </div>
          <div className="mu-card-header-title">
            <div className="mu-card-title">{user?.username}</div>
            <div className="mu-card-sub-title">ID: {user?.id}</div>
          </div>
          <div className="mu-card-right" onClick={() => navigate("/")}>
            <FontAwesomeIcon icon={faHomeAlt} />
          </div>
        </div>
        <div className=" mu-card-media">
          <img src={bgMember} alt="" />
          <div className="mu-card-media-title">
            <div className="mu-card-title">{i18next.t("my_center")}</div>
            <div className="mu-card-sub-title">{i18next.t("note1")}</div>
          </div>
        </div>
      </div>
      <div className="no_inbox lbox_f_br">
        <div
          className="link"
          onClick={() => {
            if (user?.is_verify !== 2) {
              navigate("/member/kyc");
            }
          }}
        >
          <div className="lbox">
            <div className="lbox_l flex items-center">
              <FontAwesomeIcon
                icon={faUserCheck}
                width="18"
                fontSize="18"
                color="#78C9EB"
              />
              <span className="ml-[10px] f16 ">
                {i18next.t("name_verification")}
              </span>
            </div>
            <div className="lbox_r">
              <FontAwesomeIcon icon={faCheck} width="16" color="#78C9EB" />
            </div>
          </div>
        </div>
        <Link to="/member/sharing">
          <div className="lbox">
            <div className="lbox_l flex items-center">
              <svg
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                fontSize="18"
              >
                <path
                  d="M901.12 1024H122.88C57.344 1024 4.096 970.752 4.096 905.216v-389.12c0-43.008 22.528-79.872 55.296-100.352 32.768-18.432 71.68-18.432 106.496 2.048l311.296 186.368c20.48 12.288 47.104 12.288 67.584 0L860.16 415.744c36.864-18.432 77.824-16.384 108.544 4.096s49.152 55.296 49.152 98.304v389.12c2.048 63.488-51.2 116.736-116.736 116.736zM112.64 493.568c-2.048 0-4.096 0-6.144 2.048-6.144 4.096-10.24 12.288-10.24 22.528v389.12c0 14.336 12.288 26.624 26.624 26.624h778.24c14.336 0 26.624-12.288 26.624-26.624v-389.12c0-10.24-2.048-18.432-6.144-20.48-4.096-2.048-10.24-2.048-20.48 2.048l-309.248 186.368c-49.152 30.72-112.64 30.72-161.792 0L120.832 495.616c-4.096 0-6.144-2.048-8.192-2.048zM815.104 376.832c-24.576 0-45.056-20.48-45.056-45.056v-204.8c0-18.432-16.384-34.816-34.816-34.816H288.768c-18.432 0-34.816 16.384-34.816 34.816v204.8c0 24.576-20.48 45.056-45.056 45.056-24.576 0-45.056-20.48-45.056-45.056v-204.8C163.84 55.296 219.136 0 288.768 0h444.416C802.816 0 860.16 55.296 860.16 124.928v204.8c0 26.624-20.48 47.104-45.056 47.104z"
                  fill="#78C9EB"
                />
                <path
                  d="M593.92 370.688h-165.888c-24.576 0-45.056-20.48-45.056-45.056 0-24.576 20.48-45.056 45.056-45.056H593.92c24.576 0 45.056 20.48 45.056 45.056 2.048 24.576-18.432 45.056-45.056 45.056z"
                  fill="#78C9EB"
                />
                <path
                  d="M466.944 407.552v-165.888c0-24.576 20.48-45.056 45.056-45.056 24.576 0 45.056 20.48 45.056 45.056v165.888c0 24.576-20.48 45.056-45.056 45.056-24.576 2.048-45.056-18.432-45.056-45.056z"
                  fill="#78C9EB"
                />
              </svg>
              <span className="ml-[10px] f16 ">{i18next.t("src_invite")}</span>
            </div>
          </div>
        </Link>

        <Link to="/member/notice">
          <div className="lbox ">
            <div className="lbox_l flex items-center">
              <svg
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
              >
                <path
                  d="M512 64c247.424 0 448 200.576 448 448s-200.576 448-448 448S64 759.424 64 512 264.576 64 512 64z m0 85.333333C311.701333 149.333333 149.333333 311.701333 149.333333 512s162.368 362.666667 362.666667 362.666667 362.666667-162.368 362.666667-362.666667S712.298667 149.333333 512 149.333333z m21.333333 277.333334a21.333333 21.333333 0 0 1 21.333334 21.333333v298.666667a21.333333 21.333333 0 0 1-21.333334 21.333333h-42.666666a21.333333 21.333333 0 0 1-21.333334-21.333333V448a21.333333 21.333333 0 0 1 21.333334-21.333333h42.666666z m0-170.666667a21.333333 21.333333 0 0 1 21.333334 21.333333v42.666667a21.333333 21.333333 0 0 1-21.333334 21.333333h-42.666666a21.333333 21.333333 0 0 1-21.333334-21.333333v-42.666667a21.333333 21.333333 0 0 1 21.333334-21.333333h42.666666z"
                  fill="#78C9EB"
                />
              </svg>
              <span className="ml-[10px] f16 ">
                {i18next.t("complaint_suggestion")}
              </span>
            </div>
          </div>
        </Link>
        <a href="https://download.bufinix.com/">
          <div className="lbox">
            <div className="lbox_l flex items-center">
              <svg
                width="18"
                fontSize="18"
                height="18"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.6777 20.271C7.27476 21.3181 4 25.2766 4 30C4 35.5228 8.47715 40 14 40C14.9474 40 15.864 39.8683 16.7325 39.6221"
                  stroke="#78C9EB"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M36.0547 20.271C40.4577 21.3181 43.7324 25.2766 43.7324 30C43.7324 35.5228 39.2553 40 33.7324 40C32.785 40 31.8684 39.8683 30.9999 39.6221"
                  stroke="#78C9EB"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M36 20C36 13.3726 30.6274 8 24 8C17.3726 8 12 13.3726 12 20"
                  stroke="#78C9EB"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M17.0654 30.119L23.9999 37.0764L31.1318 30"
                  stroke="#78C9EB"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M24 20V33.5382"
                  stroke="#78C9EB"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span className="ml-[10px] f16 ">
                {i18next.t("client_download")}
              </span>
            </div>
          </div>
        </a>
        <Link to="/member/about">
          <div className="lbox  lbox-j">
            <div className="lbox_l flex items-center">
              <FontAwesomeIcon
                icon={faQuestionCircle}
                width="18"
                fontSize="18"
                color="#78C9EB"
              />
              <span className="ml-[10px] f16 ">
                {i18next.t("introduction")}
              </span>
            </div>
          </div>
        </Link>
        <Link to="/member/password">
          <div className="lbox lbox-j">
            <div className="lbox_l flex items-center">
              <FontAwesomeIcon
                icon={faLock}
                width="18"
                fontSize="18"
                color="#78C9EB"
              />
              <span className="ml-[10px] f16 ">
                {i18next.t("modify_password")}
              </span>
            </div>
          </div>
        </Link>
        <Link to="/cskh">
          <div className="lbox">
            <div className="lbox_l flex items-center">
              <FontAwesomeIcon
                icon={faHeadphones}
                width="18"
                fontSize="18"
                color="#78C9EB"
              />
              <span className="ml-[10px] f16 ">{i18next.t("customer")}</span>
            </div>
          </div>
        </Link>
        <div onClick={onLogout}>
          <div className="lbox">
            <div className="lbox_l flex items-center ">
              <FontAwesomeIcon
                icon={faPowerOff}
                width="18"
                fontSize="18"
                color="#78C9EB"
              />
              <span className="ml-[10px] f16 ">{i18next.t("log_out")}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default MemberPage;
