import React, { useEffect, useMemo, useState } from "react";
import { useStores } from "_common/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { NotificationManager } from "react-notifications";
import { PaymentApi } from "states/api/payment";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { formatNumber } from "_common/utils/formatValue";
import i18next from "i18next";
import { handleCopyLink } from "_common/utils/helper";
import UploadFile from "_common/component/UploadFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLongArrowAltLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { useNotify } from "_common/component/NotifyProvider";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import QRCode from "react-qr-code";
import classNames from "classnames";

const schema = yup
  .object({
    amount: yup.number().required(i18next.t("verify_require")),
  })
  .required();

export default function Deposit() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { pushNotify } = useNotify();

  const symbol = params.get("symbol");
  const network = params.get("network");

  const [address, setAddress] = useState();
  const [back_images, setBackImage] = useState();

  const {
    authStore: { user, general, banks, game_wallet, coins },
  } = useStores();

  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors, isValid, isSubmitted },
    getValues,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const mainCoin = useMemo(
    () => coins?.find((obj) => obj?.symbol === symbol),
    [coins, symbol]
  );

  useEffect(() => {
    if (!mainCoin) return;
    if (symbol === "usdt") {
      if (network === "trc20") {
        setAddress(mainCoin?.trc20_address);
      } else if (network === "erc20") {
        setAddress(mainCoin?.erc20_address);
      } else {
        setAddress(mainCoin?.primary_address);
      }
    } else {
      setAddress(mainCoin?.primary_address);
    }
  }, [mainCoin, symbol, network]);

  const { mutate: onSubmit, isLoading } = useMutation(
    (variables) =>
      PaymentApi.deposit({
        params: {
          ...variables,
          symbol,
          receive_address: address,
          network,
          payment_image: back_images,
          type: "deposit",
        },
      }),
    {
      onSuccess: (res) => {
        reset();
        setBackImage();
        queryClient.invalidateQueries(["get_profile"]);
        queryClient.invalidateQueries(["payment", "list"]);
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("withdrawal_successful")
        );
        pushNotify({
          type: "deposit",
        });
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const onSave = (values) => {
    if (isLoading) return null;
    const amount = values?.amount;
    if (amount < Number(general?.min_deposit || 0))
      return NotificationManager.error(
        `${i18next.t("enter_large_amount_msg")} ${formatNumber(
          general?.min_deposit
        )}`
      );
    if (amount > Number(general?.max_deposit || 0))
      return NotificationManager.error(
        `${i18next.t("enter_smaller_amount_msg")} ${formatNumber(
          general?.max_deposit
        )}`
      );
    onSubmit({ ...values });
    return null;
  };

  const onReceiveBackImages = (images) => {
    setBackImage(images[0]?.url);
  };

  return (
    <div className="container">
      <HeaderTop title={i18next.t("deposit")} />
      <div id="withdraw" className="pt-[50px] px-[15px]">
        <div className="contentbox_line">
          <div className="line_1">
            <span className="fzmm fe6im">{mainCoin?.name}</span>
          </div>
          <div className="line_2" onClick={() => navigate("/member/recharge")}>
            <span className="fzmm text-[#b6b6b6] font-[12px] mr-[5px]">
              {i18next.t("select_currency")}
            </span>
            <FontAwesomeIcon icon={faChevronRight} color="#707A8A" />
          </div>
        </div>
        <div className="imgbox">
          {symbol === "usdt" && (
            <div className="flex justify-center gap-2">
              <div
                className={classNames("imgbox_3 fl czline", {
                  showQr: network === "erc20",
                })}
                onClick={() =>
                  navigate("/member/deposit?symbol=usdt&network=erc20", {
                    replace: true,
                  })
                }
              >
                <span className="fzmm">ERC20</span>
              </div>
              <div
                className={classNames("imgbox_3 fl czline", {
                  showQr: network === "trc20",
                })}
                onClick={() =>
                  navigate("/member/deposit?symbol=usdt&network=trc20", {
                    replace: true,
                  })
                }
              >
                <span className="fzmm">TRC20</span>
              </div>
            </div>
          )}

          <div className="logobox !h-[230px] mt-[10px] flex justify-center">
            <div className="w-[200px] h-[200px] mt-[20px]">
              {address && (
                <QRCode
                  size={256}
                  viewBox="0 0 256 256"
                  className="w-[200px] h-[200px]"
                  value={address}
                />
              )}
            </div>
          </div>
          <div className="imgbox_4 address">
            <div className="imgbox_7  ">
              <span className="fzmm fe6im text-center w-[100%]">{address}</span>
              <div className="text-center w-[100%] py-[10px]">
                <span
                  className="text-[#78C9EB] text-[16px]"
                  onClick={() => handleCopyLink(address)}
                >
                  COPY
                </span>
              </div>
            </div>
          </div>
        </div>
        <form className="imgbox" onSubmit={handleSubmit(onSave)}>
          <div className="imgbox_4 h-[350px] !border-[0] !mt-[0]">
            <div className="imgbox_5">
              <span className="fzmm fe6im">{i18next.t("deposit_amount")}</span>
            </div>
            <div className="zz_inputbox">
              <input
                type="number"
                inputMode="decimal"
                min={0}
                step={0.00000001}
                className="zz_input"
                {...register("amount")}
              />
              <span className="text-[#A5A5A5]">{mainCoin?.name}</span>
            </div>
            <div className="imgbox_5">
              <span className="fzmm fe6im">{i18next.t("deposit_upload")}</span>
            </div>
            <div className="imgbox_6">
              <UploadFile
                onReceiveImages={onReceiveBackImages}
                image={back_images}
                folder="payment"
              />
            </div>
            <div className="imgbox_8 mt-[20px]">
              <div className="imgbox_10" id="sumbtn">
                <button
                  type="submit"
                  className="fzmm w-full uppercase"
                  disabled={!isValid}
                >
                  {i18next.t("deposit")}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
